import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImgService1 from '../../assets/images/icon/service-one/service-1.png'
import ImgService2 from '../../assets/images/icon/service-one/service-2.png'
import ImgService3 from '../../assets/images/icon/service-one/service-3.png'
import ImgService4 from '../../assets/images/icon/service-one/service-4.png'
import ImgService5 from '../../assets/images/icon/service-one/service-5.png'

import ImgServiceItem1 from '../../assets/images/service/service-item-1.jpg'
import ImgServiceItem2 from '../../assets/images/service/service-item-2.jpg'
import ImgServiceItem3 from '../../assets/images/service/service-item-3.jpg'
import ImgServiceItem4 from '../../assets/images/service/service-item-4.jpg'
import ImgServiceItem5 from '../../assets/images/service/service-item-5.jpg'

const AllServices = () => {
    const navigate = useNavigate();

    const onServiceDetailPage = (e) => {
        e.preventDefault();
        navigate('/service-detail');
        window.location.reload();
    }

    return (
        <div class="service-area style-1">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-6">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem1})` }}>
                            <div class="more-btn-wrapper">
                                <a href="" onClick={onServiceDetailPage} class="more-btn">
                                    <i class="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div class="info-card-inner">
                                <div class="content-wrapper">
                                    <div class="title-wrapper">
                                        <div class="icon">
                                            <img src={ImgService1} alt="icon" />
                                        </div>
                                        <h2 class="title"><a href="" >AI Development</a></h2>
                                    </div>
                                    <div class="content">
                                        <p class="desc">AI drives innovation, solving complex problems and unlocking opportunities across sectors. We leverage skilled experts and cutting-edge tech to shape the future.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem2})` }}>
                            <div class="more-btn-wrapper">
                                <a href="" onClick={onServiceDetailPage} class="more-btn" >
                                    <i class="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div class="info-card-inner">
                                <div class="content-wrapper">
                                    <div class="title-wrapper">
                                        <div class="icon">
                                            <img src={ImgService2} alt="icon" />
                                        </div>
                                        <h2 class="title"><a href="">Technology Training</a></h2>
                                    </div>
                                    <div class="content">
                                        <p class="desc">Our all-encompassing database services effortlessly manage data retrieval, storage, and optimization, ensuring seamless operations and maximum efficiency.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem3})` }}>
                            <div class="more-btn-wrapper">
                                <a href="" onClick={onServiceDetailPage} class="more-btn">
                                    <i class="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div class="info-card-inner">
                                <div class="content-wrapper">
                                    <div class="title-wrapper">
                                        <div class="icon">
                                            <img src={ImgService3} alt="icon" />
                                        </div>
                                        <h2 class="title"><a href="">Professional Consultancy</a></h2>
                                    </div>
                                    <div class="content">
                                        <p class="desc">Benefit from seasoned experts offering professional guidance and direction to overcome specific business challenges.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem4})` }}>
                            <div class="more-btn-wrapper">
                                <a href="" onClick={onServiceDetailPage} class="more-btn">
                                    <i class="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div class="info-card-inner">
                                <div class="content-wrapper">
                                    <div class="title-wrapper">
                                        <div class="icon">
                                            <img src={ImgService4} alt="icon" />
                                        </div>
                                        <h2 class="title"><a href="">Application Development</a></h2>
                                    </div>
                                    <div class="content">
                                        <p class="desc">Our bespoke application development services are meticulously crafted to align with each client's distinct business goals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem5})` }}>
                            <div class="more-btn-wrapper">
                                <a href="" class="more-btn" onClick={onServiceDetailPage}>
                                    <i class="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div class="info-card-inner">
                                <div class="content-wrapper">
                                    <div class="title-wrapper">
                                        <div class="icon">
                                            <img src={ImgService5} alt="icon" />
                                        </div>
                                        <h2 class="title"><a href="">Cloud Technology</a></h2>
                                    </div>
                                    <div class="content">
                                        <p class="desc">Engage in providing assistance and solutions utilizing cloud computing, ensuring seamless operations and scalability.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default AllServices;
