import React, { useEffect, useState } from 'react';

import portfolioP1 from '../assets/images/portfolio/p1.jpg'
import portfolioP2 from '../assets/images/portfolio/p2.jpg'
import portfolioP3 from '../assets/images/portfolio/p3.jpg'
import portfolioP4 from '../assets/images/portfolio/p4.jpg'


const FeaturedProjects = () => {

    return (
        <div class="portfolio-area style-2">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title">
                            <div class="sec-content">
                                <span class="short-title">Projects</span>
                                <h2 class="title">Check Our Featured <br /> Projects</h2>
                            </div>
                            {/* <div class="sec-desc">
                                <a href="#" class="theme-btn">View All</a>
                            </div> */}
                        </div>
                        <div class="portfolio-wrapper">
                            <div class="portfolio-card">
                                <span class="counter-number">01</span>
                                <div class="content">
                                    <span class="short-title">Application Design</span>
                                    <h2 class="title"><a href="">Application Development</a></h2>

                                    <div class="image">
                                        <img src={portfolioP1} alt="Image" />
                                    </div>
                                </div>
                                <div class="btn-wrapper">
                                    <a href="" class="style-btn">View<i class="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div class="portfolio-card">
                                <span class="counter-number">02</span>
                                <div class="content">
                                    <span class="short-title">Web Design</span>
                                    <h2 class="title"><a href="">Web Development</a></h2>

                                    <div class="image">
                                        <img src={portfolioP2} alt="Image" />
                                    </div>
                                </div>
                                <div class="btn-wrapper">
                                    <a href="" class="style-btn">View<i class="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div class="portfolio-card">
                                <span class="counter-number">03</span>
                                <div class="content">
                                    <span class="short-title">Manual & Automation</span>
                                    <h2 class="title"><a href="">Software Testing</a></h2>

                                    <div class="image">
                                        <img src={portfolioP3} alt="Image" />
                                    </div>
                                </div>
                                <div class="btn-wrapper">
                                    <a href="" class="style-btn">View<i class="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div class="portfolio-card">
                                <span class="counter-number">04</span>
                                <div class="content">
                                    <span class="short-title">Web Design</span>
                                    <h2 class="title"><a href="">AI Development</a></h2>

                                    <div class="image">
                                        <img src={portfolioP4} alt="Image" />
                                    </div>
                                </div>
                                <div class="btn-wrapper">
                                    <a href="" class="style-btn">View<i class="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default FeaturedProjects;
