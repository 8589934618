import React, { useState } from 'react';

import FileImg from "../assets/images/icon/process-step/file.png"
import DesktopImg from "../assets/images/icon/process-step/desktop.png"
import PaperBirdImg from "../assets/images/icon/process-step/paper-bird.png"
import PencilImg from "../assets/images/icon/process-step/pencil.png"


const ProcessMethodology = () => {

    return (
        <div class="process-step-area style-1 background-light-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title justify-content-center text-center">
                            <div class="sec-content">
                                <span class="short-title">Process</span>
                                <h2 class="title">Our Operational Methodology</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-3">
                        <div class="process-step style-1">
                            <div class="icon">
                                <img decoding="async" src={FileImg} alt="icon" />
                                <div class="count">
                                    <span>01</span>
                                </div>
                            </div>
                            <div class="content">
                                <h2 class="title">Research</h2>
                                <p class="desc">Diligent research aimed at enhancing and maximising project outcomes for sustained success and continual improvement.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                        <div class="process-step style-1">
                            <div class="icon">
                                <img decoding="async" src={PencilImg} alt="icon" />
                                <div class="count">
                                    <span>02</span>
                                </div>
                            </div>
                            <div class="content">
                                <h2 class="title">Design</h2>
                                <p class="desc">Crafting exceptional designs with precision and creativity to uplift and transform our clients' businesses.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                        <div class="process-step style-1">
                            <div class="icon">
                                <img decoding="async" src={DesktopImg} alt="icon" />
                                <div class="count">
                                    <span>03</span>
                                </div>
                            </div>
                            <div class="content">
                                <h2 class="title">Development</h2>
                                <p class="desc">We engage in concurrent creation and rigorous testing throughout the development phase to ensure a seamless, high-quality production process.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                        <div class="process-step style-1">
                            <div class="icon">
                                <img decoding="async" src={PaperBirdImg} alt="icon" />
                                <div class="count">
                                    <span>04</span>
                                </div>
                            </div>
                            <div class="content">
                                <h2 class="title">Deliver</h2>
                                <p class="desc">Ensuring comprehensive understanding and successful deployment, we deliver the product with dedicated customer support to facilitate seamless implementation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcessMethodology;
