import React, { useEffect, useState } from 'react';
import backgroundsectionimage from '../assets/images/section-bg/faq.jpg'
import companylogo from '../assets/images/icon/company-logo.png';


const FaqArea = () => {

    return (
        <div className="faq-area style-1">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-7 col-xl-6 col-lg-6">
                        <div className="faq-img-wrapper">
                            <div className="image-wrapper">
                                <div className="image-inner">
                                    <img className="" src={backgroundsectionimage} alt="Image" />
                                </div>
                                <div className="company-foundation">
                                    <span className="counter">10</span>
                                    <span className="title">Successful <br /> Years</span>
                                </div>

                                <div className="company-label">
                                    <div className="circle-rotate-wrapper">
                                        <div className="circle-rotate-inner">
                                            <div className="circle-rotate-text" data-circle-text='{"radius": 90, "forceWidth": true,"forceHeight": true}'>
                                                Award Winning    Digital Agency
                                            </div>
                                        </div>
                                    </div>

                                    <div className="icon">
                                        <img src={companylogo} alt="icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-5 col-xl-6 col-lg-6">
                        <div className="accordion-wrapper style-two">
                            <div className="section-title">
                                <div className="sec-content">
                                    <span className="short-title">Why Choose Us</span>
                                    <h2 className="title">We Embraced Unconventional Thinking</h2>
                                    <div className="sec-desc">
                                        <p>Our team excels with expertise, experience, and adaptability, ensuring excellence in all operations and endeavours.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-box-wrapper" id="appointmentAreaStyle1FAQ">
                                <div className="accordion-list-item">
                                    <div id="headingOne">
                                        <div className="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <h3 className="accordion-title">Software & Technology</h3>
                                        </div>
                                    </div>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#appointmentAreaStyle1FAQ">
                                        <div className="accordion-item-body">
                                            <p>Revolutionise industries, enhancing efficiency, innovation, and progress, empowering businesses, and enriching lives with endless possibilities and transformative solutions.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-list-item">
                                    <div id="headingTwo">
                                        <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <h3 className="accordion-title">Highly qualified team members</h3>
                                        </div>
                                    </div>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#appointmentAreaStyle1FAQ">
                                        <div className="accordion-item-body">
                                            <p>Our highly qualified team members bring expertise, experience, and adaptability, ensuring excellence in every aspect of our operations and endeavours.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-list-item">
                                    <div id="headingThree">
                                        <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <h3 className="accordion-title">Passion & dedication</h3>
                                        </div>
                                    </div>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#appointmentAreaStyle1FAQ">
                                        <div className="accordion-item-body">
                                            <p>Passion and dedication drive our team to exceed expectations, innovate relentlessly, and deliver exceptional results to our clients and stakeholders.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default FaqArea;
