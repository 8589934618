import React, { useEffect, useState } from 'react';

// Images
import portfolioP1 from '../../assets/images/portfolio/p1.jpg'
import portfolioP2 from '../../assets/images/portfolio/p2.jpg'
import portfolioP3 from '../../assets/images/portfolio/p3.jpg'
import portfolioP4 from '../../assets/images/portfolio/p4.jpg'

import companyLogo from '../../assets/images/icon/company-logo.png'
import aboutUsCardImg from '../../assets/images/about/about-card-img-2.jpg'
import backgroundImage from '../../assets/images/section-bg/cta-bg.jpg'
import ImgTeam1 from '../../assets/images/team-member/team-1.jpg'
import ImgTeam2 from '../../assets/images/team-member/team-2.jpg'
import ImgTeam3 from '../../assets/images/team-member/team-3.jpg'
import ImgTeam4 from '../../assets/images/team-member/team-4.jpg'

import user1 from '../../assets/images/testimonial/users/user-1.jpg'
import user2 from '../../assets/images/testimonial/users/user-2.jpg'
import user3 from '../../assets/images/testimonial/users/user-3.jpg'
import user4 from '../../assets/images/testimonial/users/user-4.jpg'
import user5 from '../../assets/images/testimonial/users/user-5.jpg'
import user6 from '../../assets/images/testimonial/users/user-6.jpg'


import user11 from '../../assets/images/testimonial/users/user-1-1.jpg'
import user22 from '../../assets/images/testimonial/users/user-2-2.jpg'
import user33 from '../../assets/images/testimonial/users/user-3-3.jpg'
import user44 from '../../assets/images/testimonial/users/user-4-4.jpg'
import user55 from '../../assets/images/testimonial/users/user-5-5.jpg'
import user66 from '../../assets/images/testimonial/users/user-6-6.jpg'

import quote from '../../assets/images/icon/quote.png'

import Client1 from "../../assets/images/brand-logo/client-1.png"
import Client2 from "../../assets/images/brand-logo/client-2.png"
import Client3 from "../../assets/images/brand-logo/client-3.png"
import Client4 from "../../assets/images/brand-logo/client-4.png"
import Client5 from "../../assets/images/brand-logo/client-5.png"
import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';

// 


import sectionBGImg from '../../assets/images/section-bg/cta-bg.jpg'
import QuoteSlider from '../../components/Slider/quoteSlider';
import FeaturedProjects from '../../components/featuredProjects';
import TeamMembers from '../../components/teamMembers';

// 

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import LetsTalk from '../../components/letsTalk';

// 


const AboutUs = () => {

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>

{showLoader &&
            <div id="preloader">
                <div
                    id="preloader-status"
                    style={{ backgroundImage: `url(${LoderGif})` }}
                ></div>
            </div>
            }

            <TopNavBar />

            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">About Us</h3>
                                </div>
                                {/* <div className="breadcrumb-list">
                                    <ul>
                                        <li><a href="index.html">Home</a></li>
                                        <li className="active"><a href="#">About</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-us-area style-2">
                <div className="container">
                    <div className="section-title">
                        <div className="row">
                            <div className="col-lg-6 align-self-lg-center">
                                <div className="sec-content">
                                    <span className="short-title">About Us</span>
                                    <h2 className="title">Infusing Technology <br />with Passion</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="sec-desc">
                                    <p>At our core, we're a forward-thinking software company and IT consulting group dedicated
                                        to delivering innovative solutions. With a steadfast focus on client-centric software development,
                                        we tailor custom services to meet individual needs. Our thriving innovation hub drives progress
                                        through cutting-edge research, business model optimization, and expertise in UI/UX design.</p>
                                    <p>In the digital realm, we go beyond conventional boundaries. Our seamlessly integrated cloud
                                        computing services offer scalable, secure, and highly effective solutions, pushing the limits
                                        of what's possible.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-image-wrapper">
                                <div className="about-image-card">
                                    <div className="main-img-wrapper">
                                        <div className="main-img-inner">
                                            <img className="" src={aboutUsCardImg} alt="about card img" />
                                            <div className="company-label">
                                                <div className="circle-rotate-wrapper">
                                                    <div className="circle-rotate-inner">
                                                        <div className="icon">
                                                            <div className="circle-rotate-text" data-circle-text='{"radius": 90, "forceWidth": true,"forceHeight": true}'>
                                                                Award Winning    Digital Agency
                                                            </div>
                                                            <img src={companyLogo} alt="icon" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mobile-content-wrapper">
                                    <div className="counter-card">
                                        <div className="counter-item">
                                            <div className="counter-title">
                                                <h1 className="number">
                                                    <span className="counter">15</span><sup>+</sup>
                                                </h1>
                                                <p className="title">Years of <br /> Experience</p>
                                            </div>
                                        </div>
                                        <div className="counter-item">
                                            <div className="counter-title">
                                                <h1 className="number">
                                                    <span className="counter">120</span><sup>+</sup>
                                                </h1>
                                                <p className="title">Total Countries <br /> Worldwide</p>
                                            </div>
                                        </div>
                                        <div className="counter-item">
                                            <div className="counter-title">
                                                <h1 className="number">
                                                    <span className="counter">12</span> <span>K</span><sup>+</sup>
                                                </h1>
                                                <p className="title">Successful Projects <br /> Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="review-wrapper">
                                            <div className="single-review">
                                                <div className="review">
                                                    <p>Eiusm od tempor incidi dunt ut labore et dolore magna aliqu aesd minim veniam, quesis
                                                        nostrud exerci tation ulla</p>
                                                    <div className="reviewer-info">
                                                        <span className="divider"></span>
                                                        <p>John Doe <span>Founder & CEO</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-info-card style-2">
                                <div className="about-info-content">
                                    {/* <div className="counter-card">
                                        <div className="counter-item">
                                            <div className="counter-title">
                                                <h1 className="number">
                                                    <span className="counter">15</span><sup>+</sup>
                                                </h1>
                                                <p className="title">Years of <br /> Experience</p>
                                            </div>
                                        </div>
                                        <div className="counter-item">
                                            <div className="counter-title">
                                                <h1 className="number">
                                                    <span className="counter">120</span><sup>+</sup>
                                                </h1>
                                                <p className="title">Total Countries <br /> Worldwide</p>
                                            </div>
                                        </div>
                                        <div className="counter-item">
                                            <div className="counter-title">
                                                <h1 className="number">
                                                    <span className="counter">12</span> <span>K</span><sup>+</sup>
                                                </h1>
                                                <p className="title">Successful Projects <br /> Completed</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="content-wrapper">
                                        <div className="content">
                                            <div className="single-content">
                                                <h3 className="title">Design & Development</h3>
                                                <p>Engaging in thorough project research and design for our clients involves a meticulous examination of every tool, feature, and workflow. Our goal is to identify optimal solutions through careful analysis, ensuring a comprehensive approach to design and development.</p>
                                            </div>
                                            <div className="single-content">
                                                <h3 className="title">Research and Analysis</h3>
                                                <p>Our commitment to research and analysis goes beyond surface-level investigation. We delve deeply into the intricacies of each project, leveraging insights to formulate strategies that not only address immediate needs but also anticipate future challenges.</p>
                                            </div>
                                        </div>
                                        <div className="review-wrapper">
                                            <div className="single-review">
                                                <div className="review">
                                                    <p>Global IT Solutions:
                                                        Leveraging cutting-edge technologies and expert consulting, we
                                                        elevate enterprises to new heights. Our paramount goal is client
                                                        satisfaction, and we adeptly tackle any IT challenge with success.</p>
                                                    <div className="reviewer-info">
                                                        <span className="divider"></span>
                                                        <p>Tushar Kothari<span><br />Founder & CEO</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="cta-area style-3" style={{ backgroundImage: `url(${sectionBGImg})` }}>
                <div class="image-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="call-to-action-card">
                                <div class="content">
                                    <span class="short-title">Visual Insights</span>
                                    <h2 class="title">A Transparent and Collaborative Approach to Deliver Top-Notch <br /> IT Solutions</h2>
                                </div>
                                <div class="btn-wrapper">
                                    <div class="video-popup-btn">
                                        <a href="https://www.youtube.com/watch?v=SZEflIVnhH8" class="mfp-iframe video-play">
                                            <i class="fa-solid fa-play" aria-hidden="true"></i>
                                        </a>
                                        <span class="btn-text">Watch Now</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <TeamMembers />


            <FeaturedProjects />


            {/* <QuoteSlider /> */}


            <LetsTalk />


            <FooterBar />


        </div>
    );
};

export default AboutUs;
