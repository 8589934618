import React, { useEffect, useState } from 'react'
import TopNavBar from '../../components/Navbars/topNavBar'
import FooterBar from '../../components/Navbars/footerBar'
import { useNavigate } from 'react-router-dom'

import blogdetailfeature from '../../assets/images/blog-detail/b-details-feature.jpg'

import navnextimage from '../../assets/images/blog/nav-next-img.jpg';
import navprevimage from '../../assets/images/blog/nav-prev-img.jpg'
import avatar from '../../assets/images/blog/avatar.jpg'

import commenterimage1 from '../../assets/images/commenter/commenter-1.jpg'
import commenterimage2 from '../../assets/images/commenter/commenter-2.jpg'
import commenterimage3 from '../../assets/images/commenter/commenter-3.jpg'
import commenterauthorimage from '../../assets/images/commenter/commenter-author.jpg'

import blogimagerp1 from '../../assets/images/blog/rp-1.jpg'
import blogimagerp2 from '../../assets/images/blog/rp-2.jpg'
import blogimagerp3 from '../../assets/images/blog/rp-3.jpg'

import instapost1 from '../../assets/images/instagram/instagram-1.jpg'
import instapost2 from '../../assets/images/instagram/instagram-2.jpg'
import instapost3 from '../../assets/images/instagram/instagram-3.jpg'
import instapost4 from '../../assets/images/instagram/instagram-4.jpg'
import instapost5 from '../../assets/images/instagram/instagram-5.jpg'
import instapost6 from '../../assets/images/instagram/instagram-6.jpg'

import LoderGif from '../../assets/images/icon/preeloder.gif'

const BlogDetailPage = () => {

    const navigate = useNavigate();

    const onBlogDetail = (e) => {
        e.preventDefault();
        navigate('/blogdetail');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
{showLoader &&
            <div id="preloader">
                <div
                    id="preloader-status"
                    style={{ backgroundImage: `url(${LoderGif})` }}
                ></div>
            </div>
            }

            <TopNavBar />

            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Beginner's Guide to Understand AI and Applications</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 blog-details-wrapper">
                            <article className="single-post-item">
                                <div className="post-thumbnail">
                                    <a href="#">
                                        <img src={blogdetailfeature} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Transportation</span>
                                        <span><i className="fa-solid fa-clock"></i>April 11, 2023</span>
                                        <span><a href="#"><i className="fa-solid fa-comments"></i> 34 Comments</a></span>
                                    </div>
                                    <h3 className="post-title">
                                        <h3>Data privacy and protection in the digital age</h3>
                                    </h3>
                                    <div className="post-content">
                                        <p>Data privacy refers to the protection of personal information from unauthorized access, use, or disclosure. With the proliferation of online services and interconnected devices, safeguarding sensitive data is crucial to maintaining trust in the digital ecosystem. Breaches can lead to identity theft, financial loss, and erosion of individual privacy.</p>
                                        <p>Governments worldwide recognize the importance of data protection and have implemented stringent regulations. Examples include the General Data Protection Regulation (GDPR) in the European Union and the California Consumer Privacy Act (CCPA) in the United States. These regulations establish guidelines for collecting, processing, and storing personal data, holding organizations accountable for ensuring data privacy.</p>
                                        <blockquote>
                                            <p>Data privacy is not just a compliance issue; it is a fundamental human right in the digital era, requiring a delicate balance between innovation and safeguarding individual liberties.</p>
                                            <footer>— Emily Chang, Chief Privacy Officer at a leading cybersecurity firm.</footer>
                                        </blockquote>
                                        <p>Despite regulations, challenges persist. Cybersecurity threats, data breaches, and the constant evolution of technology create ongoing risks. Balancing the need for data-driven insights with privacy concerns requires continuous adaptation of security measures and compliance strategies.</p>
                                        <p>Effective data protection involves a combination of technical, organizational, and legal measures. Encryption, secure authentication, and regular audits are essential technical safeguards. Organizations must also implement robust policies, educate employees and users about privacy best practices, and stay informed about evolving regulations to ensure comprehensive data protection in the ever-changing digital landscape. As we navigate the complexities of the digital age, prioritizing data privacy is not only a legal obligation but a fundamental ethical responsibility.</p>
                                    </div>
                                    <div className="single-post-meta">
                                        <div className="blog-post-tag">
                                            <span>TAGS</span>
                                            <div className="post-tag-list">
                                                <a href="#">Chat GPT</a>
                                                <a href="#">Google Bard</a>
                                                <a href="#">Facebook</a>
                                                <a href="#">Meta</a>
                                            </div>
                                        </div>
                                        <div className="social-share">
                                            <span className="social-share-title">SHARE</span>
                                            <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a className="twitter" href="#"><i className="fa-brands fa-twitter"></i></a>
                                            <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <div className="blog-post-nav">
                                <div className="post-navigation">
                                    <div className="post-img">
                                        <img src={navprevimage} alt="post image" />
                                    </div>
                                    <div className="text">
                                        <span><i className="fa-solid fa-angle-left"></i> Prev Post</span>
                                        <h4><a href="#" rel="prev">Flexibility and Efficiency in Software Development</a> </h4>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="post-navigation">
                                    <div className="post-img">
                                        <img src={navnextimage} alt="post image" />
                                    </div>
                                    <div className="text">
                                        <span>Next Post <i className="fa-solid fa-angle-right"></i></span>
                                        <h4><a href="#" rel="next">When the musics over turn off the light</a> </h4>
                                    </div>
                                </div>
                            </div> */}
                            <div className="author-info">
                                <div className="author-thumb text-center">
                                    <a href="#">
                                        <img alt="" src={avatar} className="avatar avatar-190 photo media-object"
                                            height="120" width="120" />
                                    </a>
                                </div>
                                <div className="author-text text-center">
                                    <h3><a href="#">Elon Musk</a></h3>
                                    <span className="designation">Senior Writer</span>
                                    <p>A visionary entrepreneur and business magnate, Elon Musk is renowned for founding and leading companies like SpaceX, Tesla, Neuralink, and The Boring Company. Musk is known for his ambitious goals, including advancing space exploration, revolutionizing the automotive industry with electric vehicles, and developing brain-computer interface technology. His innovative spirit has left an indelible mark on various industries, shaping the future of technology and sustainability. Musk's endeavors reflect a commitment to addressing global challenges and pushing the boundaries of what is possible.</p>
                                    {/* <div className="author-post">
                                        <a href="#">All Posts <i className="fa-solid fa-arrow-up-right"></i></a>
                                    </div> */}
                                </div>
                            </div>

                            {/* <div className="post-comments">
                                <div className="blog-coment-title">
                                    <h2>20 Comments</h2>
                                </div>
                                <div className="latest-comments">
                                    <ul>
                                        <li className="comment">
                                            <div className="comments-box">
                                                <div className="comments-avatar">
                                                    <img src={commenterimage1} alt="Commenter Author"
                                                        height="84" width="84" />
                                                </div>
                                                <div className="comments-text">
                                                    <div className="avatar-name">
                                                        <h5><a href="#">Mike Anderson</a></h5>
                                                        <span>August 7, 2023  4.54 Am</span>
                                                        <a href="#" className="comment-reply-link">Reply</a>
                                                    </div>
                                                    <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consecte non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaer at mquam eius modi teeius modi tempora inc.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="comment depth-1">
                                            <div className="comments-box">
                                                <div className="comments-avatar">
                                                    <img src={commenterimage2} alt="Commenter Author"
                                                        height="84" width="84" />
                                                </div>
                                                <div className="comments-text">
                                                    <div className="avatar-name">
                                                        <h5><a href="#">Peter Parker</a></h5>
                                                        <span>August 7, 2023  4.54 Am</span>
                                                        <a href="#" className="comment-reply-link">Reply</a>
                                                    </div>
                                                    <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consecte non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaer at mquam eius modi teeius modi tempora inc.</p>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div className="comments-box">
                                                        <div className="comments-avatar">
                                                            <img src={commenterauthorimage}
                                                                alt="Commenter Author" height="84" width="84" />
                                                        </div>
                                                        <div className="comments-text">
                                                            <div className="avatar-name">
                                                                <h5><a href="#">David Jones</a></h5>
                                                                <span>August 7, 2023  4.54 Am</span>
                                                                <a href="#" className="comment-reply-link">Reply</a>
                                                            </div>
                                                            <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consecte non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaer at mquam eius modi teeius modi tempora inc.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="comment">
                                            <div className="comments-box">
                                                <div className="comments-avatar">
                                                    <img src={commenterimage3} alt="Commenter Author"
                                                        height="84" width="84" />
                                                </div>
                                                <div className="comments-text">
                                                    <div className="avatar-name">
                                                        <h5><a href="#">Jennifer Davis</a></h5>
                                                        <span>August 7, 2023  4.54 Am</span>
                                                        <a href="#" className="comment-reply-link">Reply</a>
                                                    </div>
                                                    <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consecte non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaer at mquam eius modi teeius modi tempora inc.</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                            {/* <div className="post-comments-form">
                                <div className="comment-respond">
                                    <div className="post-comments-title">
                                        <h2>Add A Comment</h2>
                                    </div>
                                    <form action="#" method="post" className="comment-form">
                                        <div className="row gx-2">
                                            <div className="col-xl-6">
                                                <div className="contacts-name">
                                                    <input name="author" type="text" placeholder="Your name*" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="contacts-email">
                                                    <input name="email" type="text" placeholder="Your email*" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="contacts-name">
                                                    <input name="phone" type="text" placeholder="Your phone" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="contacts-name">
                                                    <input name="website" type="text" placeholder="Your website" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="contacts-message">
                                                    <textarea name="comment" cols="20" rows="3"
                                                        placeholder="Start writing comment"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="theme-btn" type="submit">Post Comment</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> */}

                        </div>

                        <div className="col-lg-4 order-1 order-lg-2">
                            <div className="sidebar">
                                {/* <div className="widget widget_search">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Search</h4>
                                    </div>
                                    <form className="search-form" action="#" method="get">
                                        <input type="text" placeholder="Type keyword to search" />
                                        <button type="submit"><i className="fas fa-search"></i></button>
                                    </form>
                                </div> */}
                                <div className="widget widget_latest_post">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Recent Posts</h4>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp1} onClick={onBlogDetail} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="" onClick={onBlogDetail}>Data privacy and protection in the digital age</a>
                                                </h3>
                                                <span className="latest-post-meta">August 6, 2023</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp2} onClick={onBlogDetail} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="" onClick={onBlogDetail}>Remote collaboration tools and strategies</a>
                                                </h3>
                                                <span className="latest-post-meta">August 6, 2023</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp3} onClick={onBlogDetail} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href=""onClick={onBlogDetail} >When the musics over turn off the light</a>
                                                </h3>
                                                <span className="latest-post-meta">August 6, 2023</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="widget widget_categories">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Categories</h4>
                                    </div>
                                    <ul>
                                        <li><a href="#">AI Development</a>32</li>
                                        <li><a href="#">Web Design</a>12</li>
                                        <li><a href="#">Creative Design</a>54</li>
                                        <li><a href="#">Web Development</a>34</li>
                                        <li><a href="#">Photography</a>22</li>
                                        <li><a href="#">Digital Marketing</a>51</li>
                                        <li><a href="#">Social Media</a>17</li>
                                        <li><a href="#">Graphic Design</a>28</li>
                                    </ul>
                                </div> */}
                                <div className="widget widget_tag_cloud">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Tags</h4>
                                    </div>
                                    <div className="tagcloud">
                                        <a href="#">Chat GPT</a>
                                        <a href="#">Google Bard</a>
                                        <a href="#">Facebook</a>
                                        <a href="#">Meta</a>
                                        <a href="#">Online Marketing</a>
                                        <a href="#">Twitter</a>
                                        <a href="#">Web Design</a>
                                        <a href="#">Mobile Apps</a>
                                        <a href="#">Google</a>
                                    </div>
                                </div>
                                <div className="widget widget_instagram_feed">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Gallery</h4>
                                    </div>
                                    <div className="widget-instagram-feed">
                                        <div className="single-instagram-feed">
                                            <img src={instapost1} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost2} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost3} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost4} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost5} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost6} alt="instagram photo" />
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_social_profile">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Follow Us</h4>
                                    </div>
                                    <div className="social-profile">
                                        <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a className="twitter" href="#"><i className="fa-brands fa-twitter"></i></a>
                                        <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        <a className="instagram" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />

        </div>


    )
}

export default BlogDetailPage