import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './pages/aboutUs/aboutUs';
import ContactUs from './pages/contactUs/contactUs';
import HomePage from './pages/homePage/homePage';
import ServiceDetailPage from './pages/servicePage/serviceDetailPage';
import ServicePage from './pages/servicePage/servicePage';
import TeamPage from './pages/teamPage/teamPage';
import BlogPage from './pages/blogPage/blogPage';
import BlogDetailPage from './pages/blogPage/blogDetailPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/service" element={<ServicePage />} />
        {/* <Route path="/service/:id" element={<ServiceDetailPage />} /> */}
        <Route path="/service-detail" element={<ServiceDetailPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/blog" element={<BlogPage/>} />
        <Route path="/blogdetail" element={<BlogDetailPage/>}/>
      </Routes>
    </Router>
  );
}

export default App;
