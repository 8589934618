import React, { useState } from 'react';
import TopNavBar from '../../components/Navbars/topNavBar';

// Images
import HeaderLogo from '../../assets/images/logo/logo.png'
import HeaderLogoSticky from '../../assets/images/logo/sticky-logo.png'
import ImgPhoneSupport from '../../assets/images/icon/phone-support.png'
import FooterLogo from '../../assets/images/logo/footer-logo.png'

// 
import Team1 from "../../assets/images/team-member/team-1.jpg"
import Team2 from "../../assets/images/team-member/team-2.jpg"
import Team3 from "../../assets/images/team-member/team-3.jpg"
import Team4 from "../../assets/images/team-member/team-4.jpg"
import Team5 from "../../assets/images/team-member/team-5.jpg"
import Team6 from "../../assets/images/team-member/team-6.jpg"
import Team7 from "../../assets/images/team-member/team-7.jpg"
import Team8 from "../../assets/images/team-member/team-8.jpg"
import Team9 from "../../assets/images/team-member/team-9.jpg"
import Team10 from "../../assets/images/team-member/team-10.jpg"
import Team11 from "../../assets/images/team-member/team-11.jpg"
import Team12 from "../../assets/images/team-member/team-12.jpg"
import FooterBar from '../../components/Navbars/footerBar';



//

const TeamPage = () => {

    return (
        <div>
            <TopNavBar />



            <div class="page-breadcrumb-area page-bg">
                <div class="page-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="breadcrumb-wrapper">
                                <div class="page-heading">
                                    <h3 class="page-title">Team Members</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="team-page-area">
                <div class="container">
                    <div class="row gx-xl-5">
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team1} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Johnson Doe</h2>
                                            <p class="desc">Founder & CEO</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team2} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Paul Harrison</h2>
                                            <p class="desc">Head of Ideas</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team3} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Nayna Eva</h2>
                                            <p class="desc">Web Developer</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team4} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Jewel Khan</h2>
                                            <p class="desc">UI/UX Designer</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team5} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">John Smith</h2>
                                            <p class="desc">Founder & CEO</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team6} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Sarah Johnson</h2>
                                            <p class="desc">Head of Ideas</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team7} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Michael Williams</h2>
                                            <p class="desc">Web Developer</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team8} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Emily Brown</h2>
                                            <p class="desc">UI/UX Designer</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team9} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">David Jones</h2>
                                            <p class="desc">Founder & CEO</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team10} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Jennifer Davis</h2>
                                            <p class="desc">Head of Ideas</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team11} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Jessica Thompson</h2>
                                            <p class="desc">Web Developer</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={Team12} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Daniel Rodriguez</h2>
                                            <p class="desc">UI/UX Designer</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <FooterBar />


        </div>
    );
};

export default TeamPage;
