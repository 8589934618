import React, { useEffect, useState } from 'react';

// Images
import HeaderLogo from '../../assets/images/logo/logo.png'
import HeaderLogoSticky from '../../assets/images/logo/sticky-logo.png'
import ImgPhoneSupport from '../../assets/images/icon/phone-support.png'
import FooterLogo from '../../assets/images/logo/footer-logo.png'

// 
import ImgService1 from '../../assets/images/icon/service-one/service-1.png'
import ImgService2 from '../../assets/images/icon/service-one/service-2.png'
import ImgService3 from '../../assets/images/icon/service-one/service-3.png'
import ImgService4 from '../../assets/images/icon/service-one/service-4.png'
import ImgService5 from '../../assets/images/icon/service-one/service-5.png'
// 

// 
import ImgServiceItem1 from '../../assets/images/service/service-item-1.jpg'
import ImgServiceItem2 from '../../assets/images/service/service-item-2.jpg'
import ImgServiceItem3 from '../../assets/images/service/service-item-3.jpg'
import ImgServiceItem4 from '../../assets/images/service/service-item-4.jpg'
import ImgServiceItem5 from '../../assets/images/service/service-item-5.jpg'
// 

// 
import FileImg from "../../assets/images/icon/process-step/file.png"
import DesktopImg from "../../assets/images/icon/process-step/desktop.png"
import PaperBirdImg from "../../assets/images/icon/process-step/paper-bird.png"
import PencilImg from "../../assets/images/icon/process-step/pencil.png"
import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import { useNavigate } from 'react-router-dom';
import ProcessMethodology from '../../components/processMethodology';

// 


// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import LetsTalk from '../../components/letsTalk';
import AllServices from '../../components/Services/allService';

// 


const ServicePage = () => {

    const navigate = useNavigate();

    const onAboutUs = (e) => {
        e.preventDefault();
        navigate('/about');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }
            <TopNavBar />


            <div class="page-breadcrumb-area page-bg">
                <div class="page-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="breadcrumb-wrapper">
                                <div class="page-heading">
                                    <h3 class="page-title">Services</h3>
                                </div>
                                {/* <div class="breadcrumb-list">
                                    <ul>
                                        <li><a href="index.html">Home</a></li>
                                        <li class="active"><a href="#">Services</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <AllServices />



            <ProcessMethodology />



            <LetsTalk />



            <FooterBar />



        </div>
    );
};

export default ServicePage;
