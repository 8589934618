import React, { useEffect, useState } from 'react';
import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';

import aboutcardimage from '../../assets/images/about/about-card-img.jpg';
import aboutimg from '../../assets/images/about/about-img-two.jpg';

import companylogo from '../../assets/images/icon/company-logo.png';
import awardicon from '../../assets/images/icon/award.png';
import brandlogoclient1 from '../../assets/images/brand-logo/client-1.png';
import brandlogoclient2 from '../../assets/images/brand-logo/client-2.png';
import brandlogoclient3 from '../../assets/images/brand-logo/client-3.png';
import brandlogoclient4 from '../../assets/images/brand-logo/client-4.png';
import brandlogoclient5 from '../../assets/images/brand-logo/client-5.png';

import quoteicon from '../../assets/images/icon/quote.png';

import sliderimage1 from '../../assets/images/slider/user/user-1.jpg'
import sliderimage2 from '../../assets/images/slider/user/user-2.jpg'
import sliderimage3 from '../../assets/images/slider/user/user-3.jpg'
import sliderimage from '../../assets/images/slider/slider-1.jpg'

import featureicon1 from '../../assets/images/icon/feature-one/feature-1.png'
import featureicon2 from '../../assets/images/icon/feature-one/feature-2.png'
import featureicon3 from '../../assets/images/icon/feature-one/feature-3.png'

import backgroundsectionimage from '../../assets/images/section-bg/faq.jpg'


import user11 from '../../assets/images/testimonial/users/user-1-1.jpg'
import user1 from '../../assets/images/testimonial/users/user-1.jpg'
import user22 from '../../assets/images/testimonial/users/user-2-2.jpg'
import user2 from '../../assets/images/testimonial/users/user-2.jpg'
import user33 from '../../assets/images/testimonial/users/user-3-3.jpg'
import user3 from '../../assets/images/testimonial/users/user-3.jpg'
import user44 from '../../assets/images/testimonial/users/user-4-4.jpg'
import user4 from '../../assets/images/testimonial/users/user-4.jpg'
import user55 from '../../assets/images/testimonial/users/user-5-5.jpg'
import user5 from '../../assets/images/testimonial/users/user-5.jpg'
import user66 from '../../assets/images/testimonial/users/user-6-6.jpg'
import user6 from '../../assets/images/testimonial/users/user-6.jpg'




import ImgService1 from '../../assets/images/icon/service-one/service-1.png'
import ImgService2 from '../../assets/images/icon/service-one/service-2.png'
import ImgService3 from '../../assets/images/icon/service-one/service-3.png'
import ImgService4 from '../../assets/images/icon/service-one/service-4.png'
import ImgService5 from '../../assets/images/icon/service-one/service-5.png'

import ImgServiceItem1 from '../../assets/images/service/service-item-1.jpg'
import ImgServiceItem2 from '../../assets/images/service/service-item-2.jpg'
import ImgServiceItem3 from '../../assets/images/service/service-item-3.jpg'
import ImgServiceItem4 from '../../assets/images/service/service-item-4.jpg'
import ImgServiceItem5 from '../../assets/images/service/service-item-5.jpg'


// 
import ImgPortfolio1 from '../../assets/images/portfolio/portfolio-tab/p1.jpg'
import ImgPortfolio2 from '../../assets/images/portfolio/portfolio-tab/p2.jpg'
import ImgPortfolio3 from '../../assets/images/portfolio/portfolio-tab/p3.jpg'
import ImgPortfolio4 from '../../assets/images/portfolio/portfolio-tab/p4.jpg'
import { useNavigate } from 'react-router-dom';
import QuoteSlider from '../../components/Slider/quoteSlider';
import TeamMembers from '../../components/teamMembers';
import BrandSlider from '../../components/Slider/brandSlider';
import FeaturedProjects from '../../components/featuredProjects';
import StayUpdatedBlog from '../../components/Blogs/stayUpdatedBlog';
import FaqArea from '../../components/faqArea';
// 
import LoderGif from '../../assets/images/icon/preeloder.gif'
import LetsTalk from '../../components/letsTalk';
import AllServices from '../../components/Services/allService';

// 
const HomePage = () => {

    const navigate = useNavigate();

    const onAboutUs = (e) => {
        e.preventDefault();
        navigate('/about');
        window.location.reload();
    }
    const onContactUs = (e) => {
        e.preventDefault();
        navigate('/contact');
        window.location.reload();
    }

    const onServiceDetailPage = (e) => {
        e.preventDefault();
        navigate('/service-detail');
        window.location.reload();
    }
    

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }


            <TopNavBar />

            <div className="slider-area style-1">
                <div className="slider-wrapper">
                    <div className="single-slider-wrapper">
                        <div className="single-slider">
                            <div className="slider-overlay"></div>
                            <div className="container h-100 align-self-center">
                                <div className="row h-100">
                                    <div className="col-12">
                                        <div className="slider-content-wrapper">
                                            <div className="slider-content">
                                                <h1 className="slider-title">Digital * <br /> Innovation</h1>
                                                <p className="slider-short-desc">We are empowering businesses globally with cutting-edge IT solutions. Our advanced software and professional consulting services guarantee client contentment by delivering efficient resolutions to any IT obstacle business proprietors face worldwide.</p>
                                                <div className="slider-btn-wrapper">
                                                    <a href="about" className="style-btn">About Us<i className="fa fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slider-image-wrapper">
                                            <div className="animate-move-left-right">
                                                <div className="trusted-user">
                                                    <div className="user-list-wrapper">
                                                        <div className="single-user">
                                                            <img src={sliderimage1} alt="user" />
                                                        </div>
                                                        <div className="single-user">
                                                            <img src={sliderimage2} alt="user" />
                                                        </div>
                                                        <div className="single-user">
                                                            <img src={sliderimage3} alt="user" />
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <span className="counter-title">
                                                            <span className="counter-desc">
                                                                <p>Trusted By <br /> <span>2K<sup>+</sup></span> People</p>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slider-image">
                                                <img src={sliderimage} alt="feature image" />
                                            </div>
                                            <div className="company-award animate-move-top-bottom">
                                                <span className="icon">
                                                    <img src={awardicon} alt="image" />
                                                </span>

                                                <span className="title">Award <br /> Winning <br /> Agecny</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="brand-marquee-area style-1">
                <div className="brand-marquee-wrapper first-brand-marquee pb-2">
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Creative</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Smart</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Digital</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Modern</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Creative</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Smart</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Digital</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Modern</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                </div>
            </div>

            <div className="feature-area style-1">
                <div className="container">
                    <div className="row gx-6">
                        <div className="col-12">
                            <div className="section-title">
                                <div className="sec-content">
                                    <span className="short-title">Welcome to TkTechnico </span>
                                    <h2 className="title">Empowering <br /> Technology</h2>
                                </div>
                                <div className="sec-desc">
                                    <p>Empowering enterprises worldwide with cutting-edge IT innovations, we strive to uplift businesses to new heights. Our advanced software solutions and seasoned advisory services are tailored to guarantee client contentment, delivering optimal resolutions to diverse IT hurdles encountered by businesses across the globe.</p>
                                    <a href="" onClick={onAboutUs} className="simple-btn">More About us <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="info-card ">
                                <div className="info-card-inner">
                                    <div className="content-wrapper">
                                        <div className="title-wrapper">
                                            <div className="icon">
                                                <img src={featureicon1} alt="icon" />
                                            </div>
                                            <h2 className="title">Innovative Approach</h2>
                                        </div>
                                        <div className="content">
                                            <p className="desc">Our team excels with a unique problem-solving approach, blending creativity and ingenuity. Committed to excellence, we consistently strive for greatness in every aspect of our work.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="info-card ">
                                <div className="info-card-inner">
                                    <div className="content-wrapper">
                                        <div className="title-wrapper">
                                            <div className="icon">
                                                <img src={featureicon2} alt="icon" />
                                            </div>
                                            <h2 className="title">Driving Innovative Solutions</h2>
                                        </div>
                                        <div className="content">
                                            <p className="desc">At the core of our mission lies a steadfast commitment to pioneering inventive solutions that surmount obstacles, catalysing progress, and fostering success.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="info-card ">
                                <div className="info-card-inner">
                                    <div className="content-wrapper">
                                        <div className="title-wrapper">
                                            <div className="icon">
                                                <img src={featureicon3} alt="icon" />
                                            </div>
                                            <h2 className="title">Efficient Real-Time Support</h2>
                                        </div>
                                        <div className="content">
                                            <p className="desc">Committed to real-time support, ensuring swift issue resolution, enhancing user experiences, and maximizing customer satisfaction through prompt concern resolution.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-us-area style-1">
                <div className="container">
                    <div className="row position-relative">
                        <div className="col-xxl-6 col-xl-5 col-lg-6">
                            <div className="about-image-card">

                                <div className="main-img-wrapper">
                                    <div className="main-img-inner">
                                        <img className="" src={aboutcardimage} alt="about card img" />
                                    </div>
                                    <div className="video-popup-btn">
                                        <div className="circle-rotate-wrapper">
                                            <div className="circle-rotate-inner">
                                                <div className="circle-rotate-text" data-circle-text='{"radius": 90, "forceWidth": true,"forceHeight": true}'>
                                                    play video to know more about us
                                                </div>
                                            </div>
                                        </div>
                                        <a href="https://www.youtube.com/watch?v=SZEflIVnhH8" className="mfp-iframe video-play">
                                            <i className="fa-solid fa-play" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-7 col-lg-6">
                            <div className="about-info-card style-1">
                                <div className="about-info-content">
                                    <div className="section-title">
                                        <div className="sec-content">
                                            <span className="short-title">Our Story</span>
                                            <h2 className="title">Resolving IT Challenges</h2>
                                        </div>
                                    </div>
                                    <div className="desc-wrapper">
                                        <p>TkTechnico Solutions is not just a software company; it's your strategic IT partner. Our mission is to deliver comprehensive IT solutions to businesses on the rise. With a specialised focus on engineering excellence and top-notch IT services, we pave the way for your success.</p>
                                        <p>In addition to our expertise in information technology and on-premise engineering consulting, our core services encompass cloud migration and modernization, comprehensive data management solutions, and efficient information management strategies.</p>
                                    </div>
                                    <div className="btn-wrapper">
                                        <a href="" onClick={onContactUs} className="simple-btn">Get in Touch<i className="fa fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AllServices />


            <div class="portfolio-slider-area style-1">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title">
                                <div class="sec-content">
                                    <span class="short-title">Portfolio</span>
                                    <h2 class="title">Transform Your Visions <br /> into Reality </h2>
                                </div>
                                <div class="sec-desc">
                                    <a href="#" class="theme-btn">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="portfolio-fullscreen">
                    <div class="portfolio-main-wrapper">
                        <div class="single-portfolio-item active" data-tab="tab-1">
                            <div class="portfolio-cat-wrapper">
                                <span class="portfolio-cat">RESEARCH</span>
                            </div>
                            <div class="portfolio-content">
                                <h3 class="title">UI/UX research & design</h3>
                            </div>
                        </div>
                        <div class="single-portfolio-item" data-tab="tab-2">
                            <div class="portfolio-cat-wrapper">
                                <span class="portfolio-cat">Development</span>
                            </div>
                            <div class="portfolio-content">
                                <h3 class="title">Application Development</h3>
                            </div>
                        </div>
                        <div class="single-portfolio-item" data-tab="tab-3">
                            <div class="portfolio-cat-wrapper">
                                <span class="portfolio-cat">Consultancy</span>
                            </div>
                            <div class="portfolio-content">
                                <h3 class="title">Corporate Consultancy</h3>
                            </div>
                        </div>
                        <div class="single-portfolio-item" data-tab="tab-4">
                            <div class="portfolio-cat-wrapper">
                                <span class="portfolio-cat">Analysis</span>
                            </div>
                            <div class="portfolio-content">
                                <h3 class="title">Strategic Data Analysis</h3>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-fullscreen-image">
                        <div
                            id="tab-1"
                            className="tabimg active"
                            data-bg="images/portfolio/portfolio-tab/p1.jpg"
                            // style={{ backgroundImage: "url('images/portfolio/portfolio-tab/p1.jpg')" }}
                            style={{ backgroundImage: `url(${ImgPortfolio1})` }}
                        >
                        </div>
                        <div
                            id="tab-2"
                            className="tabimg"
                            data-bg="images/portfolio/portfolio-tab/p2.jpg"
                            // style={{ backgroundImage: "url('images/portfolio/portfolio-tab/p2.jpg')" }}
                            style={{ backgroundImage: `url(${ImgPortfolio2})` }}
                        >
                        </div>
                        <div
                            id="tab-3"
                            className="tabimg"
                            data-bg="images/portfolio/portfolio-tab/p3.jpg"
                            // style={{ backgroundImage: "url('images/portfolio/portfolio-tab/p3.jpg')" }}
                            style={{ backgroundImage: `url(${ImgPortfolio3})` }}
                        >
                        </div>
                        <div
                            id="tab-4"
                            className="tabimg"
                            data-bg="images/portfolio/portfolio-tab/p4.jpg"
                            // style={{ backgroundImage: "url('images/portfolio/portfolio-tab/p4.jpg')" }}
                            style={{ backgroundImage: `url(${ImgPortfolio4})` }}
                        >
                        </div>
                    </div>
                </div>
            </div>


            <FaqArea />

            <div className="brand-marquee-area style-2">
                <div className="brand-marquee-wrapper first-brand-marquee">
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Branding</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">UI/UX</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Web Design</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Smart</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Creative</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                </div>
                <div className="brand-marquee-wrapper second-brand-marquee pb-2">
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Web Design</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Marketing</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">E-Commerce</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Creative</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Smart</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                </div>
            </div>


            <TeamMembers />


            {/* <div className="counter-up-area style-1">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="counter-card">
                                <div className="counter-item">
                                    <div className="counter-title">
                                        <h1 className="number">
                                            <span className="counter">150</span>
                                        </h1>
                                        <p className="title">Products</p>
                                    </div>
                                </div>
                                <div className="counter-item">
                                    <div className="counter-title">
                                        <h1 className="number">
                                            <span className="counter">10</span><sup>+</sup>
                                        </h1>
                                        <p className="title">Clients</p>
                                    </div>
                                </div>
                                <div className="counter-item">
                                    <div className="counter-title">
                                        <h1 className="number">
                                            <span className="counter">5</span> <span>K</span><sup>+</sup>
                                        </h1>
                                        <p className="title">Users</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            <QuoteSlider />


            {/* <BrandSlider /> */}


            <FeaturedProjects />


            <StayUpdatedBlog />


            <LetsTalk />

            <FooterBar />

        </div>
    )

};

export default HomePage;
