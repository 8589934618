import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import latestpost1 from '../../assets/images/latest-posts/lp1.jpg'
import latestpost2 from '../../assets/images/latest-posts/lp2.jpg'
import latestpost3 from '../../assets/images/latest-posts/lp3.jpg'
import latestpostfeature from '../../assets/images/latest-posts/lpfeature-1.jpg'

const StayUpdatedBlog = () => {

    const navigate = useNavigate();

    const onBlogDetail = (e) => {
        e.preventDefault();
        navigate('/blogdetail');
        window.location.reload();
    }
    const onBlog = (e) => {
        e.preventDefault();
        navigate('/blog');
        window.location.reload();
    }

    return (
        <div className="latest-posts-area style-1">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <div className="sec-content">
                                <span className="short-title">Stay Updated</span>
                                <h2 className="title">Latest News & Updates <br /> from Our Blog</h2>
                            </div>
                            <div className="sec-desc">
                                <a href="#" onClick={onBlog} className="theme-btn">View All</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="post-card style-2">
                            <div className="image">
                                <img src={latestpostfeature} alt="post-1" />
                                <a href="#" onClick={onBlogDetail} className="btn-link">
                                    <i className="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div className="content">
                                <span className="post-cat"><a href="#">Web Development</a></span>
                                <h2 className="title">
                                    <a href="">Jim Morison says when the musics over turn off the light</a>
                                </h2>
                                <div className="post-meta">
                                    <span className="single-post-meta">
                                        <i className="fa fa-clock"></i>
                                        <span>July 16, 2023</span>
                                    </span>
                                    <span className="single-post-meta">
                                        <i className="fa fa-user"></i>
                                        <span>Jewel Khan</span>
                                    </span>
                                    <span className="single-post-meta">
                                        <i className="fa fa-comment"></i>
                                        <span>22</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="post-card-wrapper">
                            <div className="post-card style-1">
                                <div className="image">
                                    <img src={latestpost1} alt="post-1" />
                                    <a href="#" onClick={onBlogDetail} className="btn-link">
                                        <i className="fa fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="content">
                                    <span className="post-cat"><a href="#">Web Development</a></span>
                                    <h2 className="title">
                                        <a href="">A happy man makes others happy</a>
                                    </h2>
                                    <div className="post-meta">
                                        <span className="single-post-meta">
                                            <i className="fa fa-clock"></i>
                                            <span>July 16, 2023</span>
                                        </span>
                                        <span className="single-post-meta">
                                            <a href="#">
                                                <i className="fa fa-user"></i>
                                                Jewel Khan
                                            </a>
                                        </span>
                                        <span className="single-post-meta">
                                            <i className="fa fa-comment"></i>
                                            <span>22</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="post-card style-1">
                                <div className="image">
                                    <img src={latestpost2} alt="post-1" />
                                    <a href="" onClick={onBlogDetail} className="btn-link">
                                        <i className="fa fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="content">
                                    <span className="post-cat"><a href="#">Web Development</a></span>
                                    <h2 className="title">
                                        <a href="">Your website is your virtual home</a>
                                    </h2>
                                    <div className="post-meta">
                                        <span className="single-post-meta">
                                            <i className="fa fa-clock"></i>
                                            <span>July 16, 2023</span>
                                        </span>
                                        <span className="single-post-meta">
                                            <i className="fa fa-user"></i>
                                            <span>Jewel Khan</span>
                                        </span>
                                        <span className="single-post-meta">
                                            <i className="fa fa-comment"></i>
                                            <span>22</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="post-card style-1">
                                <div className="image">
                                    <img src={latestpost3} alt="post-1" />
                                    <a href="" onClick={onBlogDetail} className="btn-link">
                                        <i className="fa fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="content">
                                    <span className="post-cat"><a href="#">Web Development</a></span>
                                    <h2 className="title">
                                        <a href="">WordPress themes for your next website</a>
                                    </h2>
                                    <div className="post-meta">
                                        <span className="single-post-meta">
                                            <i className="fa fa-clock"></i>
                                            <span>July 16, 2023</span>
                                        </span>
                                        <span className="single-post-meta">
                                            <i className="fa fa-user"></i>
                                            <span>Jewel Khan</span>
                                        </span>
                                        <span className="single-post-meta">
                                            <i className="fa fa-comment"></i>
                                            <span>22</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default StayUpdatedBlog;
