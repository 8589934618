import React from 'react';
import teamimage1 from '../assets/images/team-member/team-1.jpg'
import teamimage2 from '../assets/images/team-member/team-2.jpg'
import teamimage3 from '../assets/images/team-member/team-3.jpg'
import teamimage4 from '../assets/images/team-member/team-4.jpg'


const TeamMembers = () => {

    return (

        <div className="team-area style-1">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <div className="sec-content">
                                <span className="short-title">Team Members</span>
                                <h2 className="title">People Leading The <br /> Industry</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gx-xl-5">
                    <div className="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                        <div className="team-member-card">
                            <div className="image">
                                <img src={teamimage1} alt="team-member" />
                            </div>
                            <div className="content-wrapper">
                                <div className="team-content-wrapper">
                                    <div className="content">
                                        <h2 className="title">Tushar Kothari</h2>
                                        <p className="desc">Founder & CEO</p>
                                    </div>

                                    <div className="social-profile-link">
                                        <div className="social-profile-btn">
                                            <i className="fa fa-plus"></i>
                                        </div>
                                        <div className="social">
                                            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                            <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                        <div className="team-member-card">
                            <div className="image">
                                <img src={teamimage2} alt="team-member" />
                            </div>
                            <div className="content-wrapper">
                                <div className="team-content-wrapper">
                                    <div className="content">
                                        <h2 className="title">Vishal Khokar</h2>
                                        <p className="desc">Head of Ideas</p>
                                    </div>
                                    <div className="social-profile-link">
                                        <div className="social-profile-btn">
                                            <i className="fa fa-plus"></i>
                                        </div>
                                        <div className="social">
                                            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                            <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                        <div className="team-member-card">
                            <div className="image">
                                <img src={teamimage3} alt="team-member" />
                            </div>
                            <div className="content-wrapper">
                                <div className="team-content-wrapper">
                                    <div className="content">
                                        <h2 className="title">Shubham Singh</h2>
                                        <p className="desc">Web Developer</p>
                                    </div>
                                    <div className="social-profile-link">
                                        <div className="social-profile-btn">
                                            <i className="fa fa-plus"></i>
                                        </div>
                                        <div className="social">
                                            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                            <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                        <div className="team-member-card">
                            <div className="image">
                                <img src={teamimage4} alt="team-member" />
                            </div>
                            <div className="content-wrapper">
                                <div className="team-content-wrapper">
                                    <div className="content">
                                        <h2 className="title">Rushikesh Nagare</h2>
                                        <p className="desc">UI/UX Designer</p>
                                    </div>
                                    <div className="social-profile-link">
                                        <div className="social-profile-btn">
                                            <i className="fa fa-plus"></i>
                                        </div>
                                        <div className="social">
                                            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                            <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
};

export default TeamMembers;
