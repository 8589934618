import React, { useEffect, useState } from 'react';
import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';

import blacklogo from '../../assets/images/logo/black-logo.png';

import blogimage1 from '../../assets/images/blog/b1.jpg'
import blogimage2 from '../../assets/images/blog/b2.jpg'
import blogimage3 from '../../assets/images/blog/b3.jpg'
import blogimage5 from '../../assets/images/blog/b5.jpg'

import blogimagerp1 from '../../assets/images/blog/rp-1.jpg'
import blogimagerp2 from '../../assets/images/blog/rp-2.jpg'
import blogimagerp3 from '../../assets/images/blog/rp-3.jpg'

import instapost1 from '../../assets/images/instagram/instagram-1.jpg'
import instapost2 from '../../assets/images/instagram/instagram-2.jpg'
import instapost3 from '../../assets/images/instagram/instagram-3.jpg'
import instapost4 from '../../assets/images/instagram/instagram-4.jpg'
import instapost5 from '../../assets/images/instagram/instagram-5.jpg'
import instapost6 from '../../assets/images/instagram/instagram-6.jpg'

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import { useNavigate } from 'react-router-dom';

// 

const BlogPage = () => {

    const navigate = useNavigate();

    const onBlogDetail = (e) => {
        e.preventDefault();
        navigate('/blogdetail');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (

        <div className="div-area style-1">
            {showLoader &&
            <div id="preloader">
                <div
                    id="preloader-status"
                    style={{ backgroundImage: `url(${LoderGif})` }}
                ></div>
            </div>
            }

            <TopNavBar />

            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Our Blog</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 blog-post">
                            <article className="single-post-item format-image">
                                <div className="post-thumbnail">
                                    <a href="" onClick={onBlogDetail}>
                                        <img src={blogimage1} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Transportation</span>
                                        <span><i className="fa-solid fa-clock"></i>April 11, 2023</span>
                                        <span><a href="#"><i className="fa-solid fa-comments"></i> 34 Comments</a></span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="" onClick={onBlogDetail}>Protecting your personal information online</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>Hoyto olor in reprehenderit in voluptate velit esse cillum dolore eu fugiatmnis iste natus error sit tem accusa nulla pariatu mmodo consequat. Duis aute irure dolor in reprehenderit in voluptate se cillum dolore eu fugiat nulla paria hitecto beatae vitae dicta sunt explicabo. Nemo enim ...</p>
                                    </div>
                                    <div className="read-more">
                                        <a href="" onClick={onBlogDetail} className="theme-btn" >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a className="twitter" href="#"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="single-post-item format-video">
                                <div className="post-video">
                                    <img src={blogimage2}  alt="Blog Image" />
                                    <div className="popup-video-wrapper">
                                        <div className="video-btn">
                                            <a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="mfp-iframe video-play">
                                                <i className="fa-solid fa-play" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>August 6, 2023</span>
                                        <span><a href="#"><i className="fa-solid fa-comments"></i>20 Comments</a></span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="" onClick={onBlogDetail}>Getting started with programming basics</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>Moyto olor in reprehenderit in voluptate velit esse cillum dolore eu fugiatmnis iste natus error sit tem accusa nulla pariatu mmodo consequat. Duis aute irure dolor in reprehenderit in voluptate se cillum dolore eu fugiat nulla paria hitecto beatae vitae dicta sunt explicabo. Nemo pnim ...</p>
                                    </div>
                                    <div className="read-more">
                                        <a href="" className="theme-btn"  onClick={onBlogDetail} >Read More</a>                  
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a className="twitter" href="#"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="single-post-item format-gallery">
                                <div className="post-gallery">
                                    <img src={blogimage3} onClick={onBlogDetail} alt="Blog Image" />
                                    <img src={blogimage3} onClick={onBlogDetail} alt="Blog Image" />
                                    <img src={blogimage3} onClick={onBlogDetail} alt="Blog Image" />
                                    <img src={blogimage3} onClick={onBlogDetail} alt="Blog Image" />
                                    <img src={blogimage3} onClick={onBlogDetail} alt="Blog Image" />
                                    <img src={blogimage3} onClick={onBlogDetail} alt="Blog Image" />
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>August 6, 2023</span>
                                        <span><a href="#"><i className="fa-solid fa-comments"></i>20 Comments</a></span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="" onClick={onBlogDetail}>When the musics over turn off the light</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>Koyto olor in reprehenderit in voluptate velit esse cillum dolore eu fugiatmnis iste natus error sit tem accusa nulla pariatu mmodo consequat. Duis aute irure dolor in reprehenderit in voluptate se cillum dolore eu fugiat nulla paria hitecto beatae vitae dicta sunt explicabo. Nemo unim ...</p>
                                    </div>
                                    <div className="read-more">
                                        <a href="" className="theme-btn" onClick={onBlogDetail} >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a className="twitter" href="#"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="single-post-item format-audio">
                                <div className="post-audio embed-responsive">
                                    <iframe
                                        src="https://w.soundcloud.com/player/?visual=true&amp;url=https%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F666328004&amp;show_artwork=true&amp;maxheight=960&amp;maxwidth=640"
                                        data-dashlane-frameid="695"
                                    ></iframe>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>August 6, 2023</span>
                                        <span><a href="#"><i className="fa-solid fa-comments"></i>20 Comments</a></span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="" onClick={onBlogDetail}>Strategies for building a thriving online store</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>Nenderit in voluptate velit esse cillum dolore eu fugiatmnis iste natus error sit tem accusa nulla pariatu mmodo consequat. Duis aute irure dolor in reprehenderit in voluptate se cillum dolore eu fugiat nulla paria hitecto aute irure dolor in repre beatae vitae dicta sunt explicabo. Nemo enim ...</p>
                                    </div>
                                    <div className="read-more">
                                        <a href="" className="theme-btn" onClick={onBlogDetail} >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a className="twitter" href="#"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="single-post-item format-image">
                                <div className="post-thumbnail">
                                    <a href="" onClick={onBlogDetail}>
                                        <img src={blogimage5} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>August 6, 2023</span>
                                        <span><a href="#"><i className="fa-solid fa-comments"></i>20 Comments</a></span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="" onClick={onBlogDetail}>Storing and accessing your files anywhere</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>Nenderit in voluptate velit esse cillum dolore eu fugiatmnis iste natus error sit tem accusa nulla pariatu mmodo consequat. Duis aute irure dolor in reprehenderit in voluptate se cillum dolore eu fugiat nulla paria hitecto aute irure dolor in repre beatae vitae dicta sunt explicabo. Nemo enim ...</p>
                                    </div>
                                    <div className="read-more">
                                        <a href="" className="theme-btn" onClick={onBlogDetail} >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a className="twitter" href="#"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <div className="basic-pagination basic-pagination-2 mb-40">
                                <ul>
                                    <li>
                                        <span aria-current="page" className="page-numbers current">1</span>
                                    </li>
                                    <li><a className="page-numbers" href="#">2</a></li>
                                    <li><a className="page-numbers" href="#">3</a></li>
                                    <li><span className="page-numbers dots">…</span></li>
                                    <li><a className="page-numbers" href="#">5</a></li>
                                    <li>
                                        <a className="next page-numbers" href="#"><i className="fa-solid fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>

                        <div className="col-lg-4 order-1 order-lg-2">
                            <div className="sidebar">
                                {/* <div className="widget widget_search">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Search</h4>
                                    </div>
                                    <form className="search-form" action="#" method="get">
                                        <input type="text" placeholder="Type keyword to search" />
                                        <button type="submit"><i className="fas fa-search"></i></button>
                                    </form>
                                </div> */}
                                <div className="widget widget_latest_post">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Recent Posts</h4>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp1} onClick={onBlogDetail} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="" onClick={onBlogDetail} >Data privacy and protection in the digital age</a>

                                                </h3>
                                                <span className="latest-post-meta">August 6, 2023</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp2} onClick={onBlogDetail} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="" onClick={onBlogDetail} >Remote collaboration tools and strategies</a>
                                                </h3>
                                                <span className="latest-post-meta">August 6, 2023</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp3} onClick={onBlogDetail} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="" onClick={onBlogDetail}  >When the musics over turn off the light</a>
                                                </h3>
                                                <span className="latest-post-meta">August 6, 2023</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget_categories">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Categories</h4>
                                    </div>
                                    <ul>
                                        <li><a href="#">AI Development</a>32</li>
                                        <li><a href="#">Web Design</a>12</li>
                                        <li><a href="#">Creative Design</a>54</li>
                                        <li><a href="#">Web Development</a>34</li>
                                        <li><a href="#">Photography</a>22</li>
                                        <li><a href="#">Digital Marketing</a>51</li>
                                        <li><a href="#">Social Media</a>17</li>
                                        <li><a href="#">Graphic Design</a>28</li>
                                    </ul>
                                </div>
                                <div className="widget widget_tag_cloud">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Tags</h4>
                                    </div>
                                    <div className="tagcloud">
                                        <a href="#">Chat GPT</a>
                                        <a href="#">Google Bard</a>
                                        <a href="#">Facebook</a>
                                        <a href="#">Meta</a>
                                        <a href="#">Online Marketing</a>
                                        <a href="#">Twitter</a>
                                        <a href="#">Web Design</a>
                                        <a href="#">Mobile Apps</a>
                                        <a href="#">Google</a>
                                    </div>
                                </div>
                                <div className="widget widget_instagram_feed">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Gallery</h4>
                                    </div>
                                    <div className="widget-instagram-feed">
                                        <div className="single-instagram-feed">
                                            <img src={instapost1} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost2} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost3} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost4} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost5} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost6} alt="instagram photo" />
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_social_profile">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Follow Us</h4>
                                    </div>
                                    <div className="social-profile">
                                        <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a className="twitter" href="#"><i className="fa-brands fa-x-twitter"></i></a>
                                        <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        <a className="instagram" href="#"><i className="fa-brands fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />

        </div>

    );
};

export default BlogPage