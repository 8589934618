import React, { useEffect, useState } from 'react';

// Images
import HeaderLogo from '../../assets/images/logo/logo.png'
import HeaderLogoSticky from '../../assets/images/logo/sticky-logo.png'
import ImgPhoneSupport from '../../assets/images/icon/phone-support.png'
import FooterLogo from '../../assets/images/logo/footer-logo.png'

// 
import ImgService1 from '../../assets/images/icon/service-one/service-1.png'
import ImgService2 from '../../assets/images/icon/service-one/service-2.png'
import ImgService3 from '../../assets/images/icon/service-one/service-3.png'
import ImgService4 from '../../assets/images/icon/service-one/service-4.png'
import ImgService5 from '../../assets/images/icon/service-one/service-5.png'
// 

// 
import ImgServiceItem1 from '../../assets/images/service/service-item-1.jpg'
import ImgServiceItem2 from '../../assets/images/service/service-item-2.jpg'
import ImgServiceItem3 from '../../assets/images/service/service-item-3.jpg'
import ImgServiceItem4 from '../../assets/images/service/service-item-4.jpg'
import ImgServiceItem5 from '../../assets/images/service/service-item-5.jpg'
// 

// 
import FileImg from "../../assets/images/icon/process-step/file.png"
import DesktopImg from "../../assets/images/icon/process-step/desktop.png"
import PaperBirdImg from "../../assets/images/icon/process-step/paper-bird.png"
import PencilImg from "../../assets/images/icon/process-step/pencil.png"

import ServiceDetailMain from "../../assets/images/service-detail/service-details-main.jpg"
import benefitdelivery from "../../assets/images/service-detail/benefite-delivery.jpg"
import outcome from "../../assets/images/service-detail/outcome.jpg"

import ailogo from "../../assets/images/icon/key-featured/ai.png"
import padlocklogo from "../../assets/images/icon/key-featured/padlock.png"
import algorithmlogo from "../../assets/images/icon/key-featured/algorithm.png"
import robotlogo from "../../assets/images/icon/key-featured/robot.png"

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import { useNavigate } from 'react-router-dom';
import LetsTalk from '../../components/letsTalk';

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'




const ServiceDetailPage = () => {
    const navigate = useNavigate();

    const onAboutUs = (e) => {
        e.preventDefault();
        navigate('/about');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div class="page-breadcrumb-area page-bg">
                <div class="page-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="breadcrumb-wrapper">
                                <div class="page-heading">
                                    <h3 class="page-title">AI Development</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="service-details-page">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 order-1 service-details-wrapper">
                            <div class="details-content">
                                <div class="feature-image">
                                    <img src={ServiceDetailMain} alt="service-details" />
                                </div>
                                <div class="text">
                                    <p>Transform your business with our AI Development Service, a dynamic solution harnessing the potential of artificial intelligence. Our skilled developers specialize in crafting tailored AI solutions, from natural language processing to predictive analytics. We prioritize innovation and collaboration, ensuring seamless integration into existing workflows. Whether automating processes or optimizing user interactions, our service delivers tangible results, unlocking new possibilities for your organization in the digital era. Stay ahead with our expertise, transforming data into actionable insights and driving sustainable growth through the power of artificial intelligence.</p>
                                    <p>Our AI Development Service offers businesses advanced solutions tailored to their unique needs. From cutting-edge algorithms to machine learning techniques, we specialize in crafting intelligent systems that enhance efficiency, decision-making, and user experiences. Our team excels in creating custom solutions such as natural language processing and computer vision. Prioritizing innovation and collaboration, we ensure seamless integration into existing workflows, driving tangible results for organizations. Whether automating processes or optimizing user interactions, our service empowers businesses to stay ahead in the digital era, transforming data into actionable insights and unlocking new possibilities for sustainable growth.</p>
                                </div>
                            </div>
                            <div class="key-featured-area">
                                <h2 class="key-featured-title">Key Factors</h2>
                                <div class="row gx-8">
                                    <div class="col-md-6">
                                        <div class="single-key-featured">
                                            <div class="title-wrapper">
                                                <div class="icon">
                                                    <img src={ailogo} alt="icon" />
                                                </div>
                                                <h4 class="title">AI Research</h4>
                                            </div>
                                            <div class="content">
                                                <p>AI research explores innovative algorithms, neural networks, and computational models, advancing technology's frontiers and shaping the future of intelligent systems.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="single-key-featured">
                                            <div class="title-wrapper">
                                                <div class="icon">
                                                    <img src={padlocklogo} alt="icon" />
                                                </div>
                                                <h4 class="title">Risk Factor Analysis</h4>
                                            </div>
                                            <div class="content">
                                                <p>AI Risk Factor Analysis assesses potential threats, vulnerabilities, and ethical implications associated with artificial intelligence deployment, ensuring responsible implementation.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="single-key-featured">
                                            <div class="title-wrapper">
                                                <div class="icon">
                                                    <img src={algorithmlogo} alt="icon" />
                                                </div>
                                                <h4 class="title">System Development</h4>
                                            </div>
                                            <div class="content">
                                                <p>We specialize in crafting innovative AI systems, leveraging advanced algorithms and cutting-edge technology to drive efficiency, productivity, and growth.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="single-key-featured">
                                            <div class="title-wrapper">
                                                <div class="icon">
                                                    <img src={robotlogo} alt="icon" />
                                                </div>
                                                <h4 class="title">Deployment</h4>
                                            </div>
                                            <div class="content">
                                                <p>Efficient AI deployment streamlines processes, enhances decision-making, and optimizes user experiences, catalyzing organizational growth and innovation in the digital landscape.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="details-overview-area">
                                <div class="overview-item">
                                    <div class="image">
                                        <img src={benefitdelivery} alt="image" />
                                    </div>
                                    <div class="content">
                                        <h4 class="title">Benefits</h4>
                                        <p>AI boosts efficiency, automates tasks, enhances decision-making, and unlocks insights, driving innovation and growth for businesses.</p>
                                        <div class="list-item">
                                            <ul>
                                                <li>
                                                    <i class="fa fa-angle-right"></i>
                                                    AI automates repetitive tasks, increasing operational efficiency and allowing employees to focus on more strategic and creative aspects.
                                                </li>
                                                <li>
                                                    <i class="fa fa-angle-right"></i>
                                                    AI analyzes vast datasets rapidly, providing valuable insights that facilitate informed decision-making, reducing errors and enhancing accuracy.
                                                </li>
                                                <li>
                                                    <i class="fa fa-angle-right"></i>
                                                    AI enables personalized user experiences through data analysis, tailoring recommendations and interactions, thereby enhancing customer satisfaction and loyalty.
                                                </li>
                                                <li>
                                                    <i class="fa fa-angle-right"></i>
                                                    AI fosters innovation by enabling the development of advanced technologies such as natural language processing, computer vision, and robotics, opening new possibilities for diverse industries.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="overview-item">
                                    <div class="image">
                                        <img src={outcome} alt="image" />
                                    </div>
                                    <div class="content">
                                        <h4 class="title">Outcome</h4>
                                        <p>The outcomes of AI implementation are diverse and impact various aspects of businesses and society, including:</p>
                                        <div class="list-item">
                                            <ul>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    AI automates tasks, reducing manual effort and time, leading to improved operational efficiency.
                                                </li>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    AI provides data-driven insights, enhancing decision-making processes with accurate and timely information.
                                                </li>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    Automation and optimization of workflows through AI contribute to increased productivity in various industries.
                                                </li>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    AI fosters the development of innovative technologies, creating new opportunities for businesses and driving economic growth.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="faq-area style-3">
                                <h4 class="faq-title">Frequently Asked Questions</h4>
                                <p></p>

                                <div class="accordion-wrapper style-one">
                                    <div class="accordion-box-wrapper" id="serviceDetailsFAQ">
                                        <div class="accordion-list-item">
                                            <div id="headingOne">
                                                <div class="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h3 class="accordion-title">What is artificial intelligence (AI)?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#serviceDetailsFAQ">
                                                <div class="accordion-item-body">
                                                    <p>Artificial Intelligence (AI) refers to the development of computer systems capable of performing tasks that typically require human intelligence. These tasks include learning, reasoning, problem-solving, perception, and language understanding.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-list-item">
                                            <div id="headingTwo">
                                                <div class="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h3 class="accordion-title"> How is machine learning different from AI?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#serviceDetailsFAQ">
                                                <div class="accordion-item-body">
                                                    <p>Machine learning is a subset of AI that focuses on developing algorithms allowing systems to learn from data and improve their performance over time. AI encompasses a broader range of technologies, including machine learning, natural language processing, and computer vision.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-list-item">
                                            <div id="headingThree">
                                                <div class="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h3 class="accordion-title">What are the potential applications of AI in business?</h3>
                                                    <div class="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#serviceDetailsFAQ">
                                                <div class="accordion-item-body">
                                                    <p>AI has various applications in business, such as automating routine tasks, improving decision-making through data analysis, enhancing customer experiences with personalized interactions, and optimizing processes for increased efficiency and productivity.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-list-item">
                                            <div id="headingFour">
                                                <div class="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h3 class="accordion-title">Are there ethical concerns associated with AI?</h3>
                                                    <div class="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#serviceDetailsFAQ">
                                                <div class="accordion-item-body">
                                                    <p>Yes, ethical concerns in AI include issues like bias in algorithms, privacy implications, job displacement, and the responsible use of AI in decision-making. Addressing these concerns involves implementing ethical guidelines, transparency in AI systems, and ongoing discussions on responsible AI development and deployment.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-xl-3 order-2 order-xl-1">
                            <div class="service-sidebar">
                                <div class="widget widget_service_categories">
                                    <ul>
                                        <li class="active">
                                            <a href="#"><i class="fa fa-brain-circuit"></i>AI Development</a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="fa fa-user-robot"></i>Robotics</a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="fa fa-screwdriver-wrench"></i>App Development</a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="fa fa-volume-high"></i>Digital Marketing</a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="fa fa-shield-check"></i>Cyber Security</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget_contact_form">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Get A Quote</h4>
                                    </div>
                                    <div class="comment-respond">
                                        <form action="#" method="post" class="comment-form">
                                            <div class="row gx-2">
                                                <div class="col-12">
                                                    <div class="contacts-name">
                                                        <input name="author" type="text" placeholder="Your name*" />
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="contacts-email">
                                                        <input name="email" type="text" placeholder="Your email*" />
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="contacts-message">
                                                        <textarea name="comment" cols="20" rows="3" placeholder="Write your message"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <button class="theme-btn" type="submit">Submit Now</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="widget widget_service_promotion">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Need Help?</h4>
                                    </div>
                                    <div class="promotion-card">
                                        <p class="short-desc">Voluptas sit aspernatur aut odit aut fugited quia conseq uuntur magni dolores</p>
                                        <a href="tel:1234567890" class="secondary-btn">
                                            <span class="icon">
                                                <i class="fa-solid fa-phone"></i>
                                            </span>
                                            <div class="btn-text">
                                                <p>Call Us</p>
                                                <h4>+ 123 456 7890</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="widget widget_service_brochure">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Company Profile</h4>
                                    </div>
                                    <div class="button-wrapper">
                                        <a href="#" class="pdf btn-text">Download PDF</a>
                                        <a href="#" class="doc btn-text">Download DOC</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>


            <LetsTalk />



            <FooterBar />



        </div>
    );
};

export default ServiceDetailPage;
