import React from 'react';
import quoteicon from '../../assets/images/icon/quote.png';
import user11 from '../../assets/images/testimonial/users/user-1-1.jpg'
import user1 from '../../assets/images/testimonial/users/user-1.jpg'
import user22 from '../../assets/images/testimonial/users/user-2-2.jpg'
import user2 from '../../assets/images/testimonial/users/user-2.jpg'
import user33 from '../../assets/images/testimonial/users/user-3-3.jpg'
import user3 from '../../assets/images/testimonial/users/user-3.jpg'
import user44 from '../../assets/images/testimonial/users/user-4-4.jpg'
import user4 from '../../assets/images/testimonial/users/user-4.jpg'
import user55 from '../../assets/images/testimonial/users/user-5-5.jpg'
import user5 from '../../assets/images/testimonial/users/user-5.jpg'
import user66 from '../../assets/images/testimonial/users/user-6-6.jpg'
import user6 from '../../assets/images/testimonial/users/user-6.jpg'



const QuoteSlider = () => {


    return (
        <div className="testimonial-slider-area style-1 background-gray">
            <div className="container">
                <div className="row position-relative">
                    <div className="testimonial-user-wrapper">
                        <div className="single-user">
                            <img src={user1} alt="user" />
                        </div>
                        <div className="single-user">
                            <img src={user2} alt="user" />
                        </div>
                        <div className="single-user">
                            <img src={user3} alt="user" />
                        </div>
                        <div className="single-user">
                            <img src={user4} alt="user" />
                        </div>
                        <div className="single-user">
                            <img src={user5} alt="user" />
                        </div>
                        <div className="single-user">
                            <img src={user6} alt="user" />
                        </div>
                    </div>
                    <div className="testimonial-slider-wrapper" id="testimonial_one">
                        <div className="slick-item">
                            <div className="testimonial-card style-1">
                                <div className="content-wrapper">
                                    <div className="quote-icon">
                                        <img src={quoteicon} alt="quote" />
                                    </div>
                                    <div className="content">
                                        <p className="text">It’s fine to celebrate success but it is more important to heed the lessons of failure.</p>
                                    </div>
                                    <div className="meta-user">
                                        <div className="user-info">
                                            <div className="image">
                                                <img src={user11} alt="user" />
                                            </div>
                                            <div className="info">
                                                <h2>Bill Gates</h2>
                                                <p>Co-founder of Microsoft</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slick-item">
                            <div className="testimonial-card style-1">
                                <div className="content-wrapper">
                                    <div className="quote-icon">
                                        <img src={quoteicon} alt="quote" />
                                    </div>
                                    <div className="content">
                                        <p className="text">You can't have everything you want, but you can have the things that really matter to you.</p>
                                    </div>
                                    <div className="meta-user">
                                        <div className="user-info">
                                            <div className="image">
                                                <img src={user22} alt="user" />
                                            </div>
                                            <div className="info">
                                                <h2>Marissa Mayer</h2>
                                                <p>President and CEO of Yahoo!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slick-item">
                            <div className="testimonial-card style-1">
                                <div className="content-wrapper">
                                    <div className="quote-icon">
                                        <img src={quoteicon} alt="quote" />
                                    </div>
                                    <div className="content">
                                        <p className="text">The biggest risk is not taking any risk… In a world that’s changing really quickly, the only strategy that is guaranteed to fail, is not taking risks.</p>
                                    </div>
                                    <div className="meta-user">
                                        <div className="user-info">
                                            <div className="image">
                                                <img src={user33} alt="user" />
                                            </div>
                                            <div className="info">
                                                <h2>Mark Zuckerberg</h2>
                                                <p>Co-founder of Facebook</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slick-item">
                            <div className="testimonial-card style-1">
                                <div className="content-wrapper">
                                    <div className="quote-icon">
                                        <img src={quoteicon} alt="quote" />
                                    </div>
                                    <div className="content">
                                        <p className="text">If you're offered a seat on a rocket ship, don't ask what seat.</p>
                                    </div>
                                    <div className="meta-user">
                                        <div className="user-info">
                                            <div className="image">
                                                <img src={user44} alt="user" />
                                            </div>
                                            <div className="info">
                                                <h2>Sheryl Sandberg</h2>
                                                <p>CEO of Lean In.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slick-item">
                            <div className="testimonial-card style-1">
                                <div className="content-wrapper">
                                    <div className="quote-icon">
                                        <img src={quoteicon} alt="quote" />
                                    </div>
                                    <div className="content">
                                        <p className="text">If you don't innovate fast, disrupt your industry, disrupt yourself, you'll be left behind.</p>
                                    </div>
                                    <div className="meta-user">
                                        <div className="user-info">
                                            <div className="image">
                                                <img src={user55} alt="user" />
                                            </div>
                                            <div className="info">
                                                <h2>John Chambers</h2>
                                                <p>CEO of Cisco</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slick-item">
                            <div className="testimonial-card style-1">
                                <div className="content-wrapper">
                                    <div className="quote-icon">
                                        <img src={quoteicon} alt="quote" />
                                    </div>
                                    <div className="content">
                                        <p className="text">Customers should be number one, Employees number two, and then only your Shareholders come at number three.</p>
                                    </div>
                                    <div className="meta-user">
                                        <div className="user-info">
                                            <div className="image">
                                                <img src={user66} alt="user" />
                                            </div>
                                            <div className="info">
                                                <h2>Jack Ma</h2>
                                                <p>Founder, Alibaba</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default QuoteSlider;